<template>
  <div class="record">
    <div class="content">Copyright @ 2019 - 2030 All Rights Reserved.</div>
    <div class="content">国家高新技术企业证书编号：GR202134002637</div>
    <div class="content">版权所有：全购网络有限公司</div>
    <div class="content">备案号：皖ICP备19022325号</div>
    <div class="content">客服邮箱：kaifa@i2f2f.com</div>
    <div class="content">公司地址：安徽省合肥市高新区国家大学科技园C102</div>
  </div>
</template>

<script>
export default {
  name: 'record'
}
</script>

<style lang="scss" scoped>
.record {
  background: #f3f3f3;
  padding: 0.16rem 0.175rem 0.155rem 0.165rem;
  font-family: PingFangSC-Regular;
  font-size: 0.11rem;
  color: #666666;
  height: 1.31rem;
}
</style>
