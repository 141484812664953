<template>
  <!-- 连锁门店系统-->
  <div class="wrapper">
    <Header></Header>
    <div class="body">
      <div class="pic1">
        <img class="img" :src="img1">
      </div>
      <div class="link1">
        <div class="title1">
            <div class="title1-big">核心功能点</div>
            <div class="title1-small">解锁更多营销玩法，持续获客人</div>
        </div>
        <div class="forms1">
          <div class="form1" v-for="(form,index) in forms1" :key="index">
            <div class="form1_icon">
              <img :src="form.img">
            </div>
            <div class="form1_content">{{form.text}}</div>
          </div>
        </div>
        <Reserve class="link1_reserve"></Reserve>
      </div>
      <div class="link2">
        <div class="title2">
          <div class="title2-big">颠覆传统用工</div>
          <div class="title2-small">为您节省更多的用工成本</div>
        </div>
        <div class="forms2" v-for="(form, index) in forms2" :key="index">
          <div class="form2">
            <div class="form2_icon">
              <img :src="form.img">
            </div>
            <div class="form2_solid"></div>
            <div class="form2_content">
              <div class="form2_title">{{form.title}}</div>
              <div class="form2_text">{{form.text}}</div>
            </div>
          </div>
        </div>
        <Reserve></Reserve>
      </div>
      <div class="link3">
        <div class="title3">
            <div class="title3-big">支持多种行业</div>
            <div class="title3-small">如果你是以下行业，推荐您使用全购门店</div>
        </div>
        <div class="forms3">
          <div class="form3" v-for="(form,index) in forms3" :key="index">
            <div class="form3_icon">
              <img :src="form.img">
            </div>
            <div class="form3_content">{{form.text}}</div>
          </div>
        </div>
        <Reserve class="link3_reserve"></Reserve>
      </div>
      <Record></Record>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/header.vue'
import Reserve from '../components/reserve.vue'
import Record from '../components/record.vue'
import Footer from '../components/footer.vue'

export default {
  name: 'page7',
  components: {
    Header,
    Reserve,
    Record,
    Footer
  },
  data () {
    return {
      img1: require('@/assets/img/page7.1.png'),
      forms1: [{
        img: require('@/assets/img/page7.2.1.png'),
        text: '多门店'
      }, {
        img: require('@/assets/img/page7.2.2.png'),
        text: '拼团'
      }, {
        img: require('@/assets/img/page7.2.3.png'),
        text: '渠道分销'
      }, {
        img: require('@/assets/img/page7.2.4.png'),
        text: '小圈'
      }, {
        img: require('@/assets/img/page7.2.5.png'),
        text: '当面付'
      }, {
        img: require('@/assets/img/page7.2.6.png'),
        text: '直播带货'
      }, {
        img: require('@/assets/img/page7.2.7.png'),
        text: '会员'
      }, {
        img: require('@/assets/img/page7.2.8.png'),
        text: '门店分销'
      }],
      forms2: [{
        img: require('@/assets/img/page7.3.1.png'),
        title: '会员、代理商、合伙人等资源可共享',
        text: '总部创建会员卡、代理商、合伙人等，直接在后台关联使用的门店即可。'
      }, {
        img: require('@/assets/img/page7.3.2.png'),
        title: '营销活动赋能',
        text: '总部可以统一定制促销活动，例如优惠券、满减等，门店会默认使用，也可以自由定制'
      }, {
        img: require('@/assets/img/page7.3.3.png'),
        title: '位置推荐',
        text: '根据用户当前所在的位置，推荐附近的门店，用户使用方便，转化率更高'
      }, {
        img: require('@/assets/img/page7.3.4.png'),
        title: '门店员工权限分配',
        text: '总部设置店长，店长拥有该门店所有权限，门店店长可以将部分权限分配给其他员工'
      }, {
        img: require('@/assets/img/page7.3.5.png'),
        title: '数据监控',
        text: '总部可以查看各门店经营数据，及时发现异常门店，及时采取措施，避免损失'
      }],
      forms3: [{
        img: require('@/assets/img/page7.4.1.png'),
        text: '生鲜蔬菜'
      }, {
        img: require('@/assets/img/page7.4.2.png'),
        text: '商超便利'
      }, {
        img: require('@/assets/img/page7.4.3.png'),
        text: '餐饮娱乐'
      }, {
        img: require('@/assets/img/page7.4.4.png'),
        text: '服装鞋帽'
      }, {
        img: require('@/assets/img/page7.4.5.png'),
        text: '生活用品'
      }, {
        img: require('@/assets/img/page7.4.6.png'),
        text: '更多行业'
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  position: relative;
  z-index: 1;
  margin: 0.42rem -0.08rem 0 -0.08rem;
}
.pic1 {
  height: 2.02rem;
  margin-bottom: 0.16rem;
}
img {
 width: 100%;
}
.link1 {
  text-align: center;
  .title1 {
    .title1-big {
      font-size: 0.17rem;
      font-family: PingFangSC-Medium;
      color: #1e1e1e;
      margin-bottom: 0.06rem;
    }
    .title1-small {
      font-family: PingFangSC-Regular;
      color: #666666;
    }
  }
  .forms1 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0.2rem 0 0 0.26rem;
    .form1 {
      margin-right: 0.365rem;
      margin-bottom: 0.3rem;
      width: 0.48rem;
      .form1_icon {
        width: 0.32rem;
        text-align: center;
        padding-left: 0.08rem;
      }
      .form2_content {
        font-family: PingFangSC-Regular
      }
    }
  }
  .link1_reserve {
    margin-top: -0.1rem;
  }
}
.link2 {
  text-align: center;
  background-color: #f7f7f7;
  padding-bottom: 0.01rem;
  .title2 {
    padding-top: 0.2rem;
    .title2-big {
      font-size: 0.17rem;
      font-family: PingFangSC-Medium;
      color: #1e1e1e;
      margin-bottom: 0.06rem;
    }
    .title2-small {
      font-family: PingFangSC-Regular;
      color: #666666
    }
  }
  .forms2 {
    margin: 0.16rem 0.175rem 0 0.175rem;
    .form2 {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 0.12rem;
      border-radius: 0.36rem;
      box-shadow: 0 0.01rem 0.04rem 0 rgba(0, 0, 0, 0.16);
      .form2_icon {
        margin: 0 0.09rem 0 0.16rem;
        width: 0.31rem;
      }
      .form2_solid {
        height: 0.355rem;
        margin-right: 0.19rem;
        border: 0.005rem solid #D3D2D2
      }
      .form2_content {
        text-align: start;
        width: 2.45rem;
        margin: 0.12rem 0 0.11rem 0;
        .form2_title {
          margin-bottom: 0.02rem;
          font-family: PingFangSC-Medium;
          font-size: 0.13rem;
        }
        .form2_text {
          font-family: PingFangSC-Regular;
          font-size: 0.12rem;
          color: #8C8C8C;
        }
      }
    }
  }
}
.link3 {
  text-align: center;
  .title3 {
    margin-top: 0.2rem;
    .title3-big {
      font-size: 0.17rem;
      font-family: PingFangSC-Medium;
      color: #1e1e1e;
      margin-bottom: 0.06rem;
    }
    .title3-small {
      font-family: PingFangSC-Regular;
      color: #666666;
    }
  }
  .forms3 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0.36rem 0 0 0.33rem;
    .form3 {
      margin-right: 0.52rem;
      margin-bottom: 0.3rem;
      width: 0.62rem;
      .form3_icon {
        width: 0.62rem;
        text-align: center;
        margin-bottom: 0.05rem;
      }
      .form3_content {
        font-family: PingFangSC-Regular;
        padding-left: 0.04rem;
      }
    }
  }
  .link3_reserve {
    margin-top: -0.1rem;
  }
}
</style>
