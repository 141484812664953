<template>
    <div class="reserve">
      <div @click="reserve">立即预约</div>
    </div>
</template>

<script>
export default {
  name: 'Reserve',
  methods: {
    reserve () {
      this.$router.push({
        path: '/nowreserve'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.reserve {
  width: 1.25rem;
  height: 0.32rem;
  line-height: 0.32rem;
  background: #3974FB;
  color: #ffffff;
  border-radius: 0.18rem;
  text-align: center;
  margin: 0.2rem auto;
}
</style>
